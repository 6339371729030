import React, { useContext } from 'react';
import { SELLER, ADMIN, CUSTOMER_SERVICE } from 'variables/roles';
import SessionContext from 'api/session/context';
import { AccessHall } from './access-hall';
import { DashboardAdmin } from 'views/pages/eiffel/DashboardAdmin/dashboard-admin';

import { DashboardSellers } from './DashboardSellers';

export function Home() {
  const {
    auth: { role, sellerIsInPublishHistory },
  } = useContext(SessionContext);

  return (
    <>
      {![SELLER].includes(role) || sellerIsInPublishHistory ? (
        <>
          {[SELLER].includes(role) && <DashboardSellers />}
          {[ADMIN, CUSTOMER_SERVICE].includes(role) && (
            <>
              <DashboardAdmin />
            </>
          )}
        </>
      ) : (
        <AccessHall />
      )}
    </>
  );
}
